<template>
  <TextInput
    v-if="countryhasUidMap[countryCode]"
    id="registrationOfficeTaxId"
    name="taxId"
    label="registration.billingAddress.taxId"
    placeholder="registration.billingAddress.placeholder.taxId"
    :validation="getValidationRules()"
    matches-message="registration.billingAddress.taxIdInvalid"
    required-message="registration.emptyError.billingAddress.taxId"
    :inner-class="hasUid ? '' : 'hidden'"
    :required="hasUid"
    info="registration.info.taxId"
    :model-value="initalValue"
    :disabled="disabled"
    :maxlength="20"
  >
    <template #label>
      <div
        class="flex flex-row gap-sm pb-[5px]"
        :class="{ 'pb-[5px]': hasUid }"
      >
        <FormLabel :label="t('registration.billingAddress.taxId')" />
        <Toggle
          id="registrationOfficeHasTaxId"
          v-model="hasUid"
          name="hasTaxId"
          :disabled="disabled"
        />
      </div>
    </template>
  </TextInput>
</template>
<script setup lang="ts">
import type { CountryCode } from '@/components/formFields/types';
import { Toggle, FormLabel, TextInput } from '@/complib';

const props = defineProps({
  countryCode: {
    type: String as PropType<CountryCode>,
    required: true,
  },
  initalValue: {
    type: String,
    default: '',
  },
  hasUid: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useTrans();

const countryhasUidMap = {
  AT: true,
  DE: false,
  NL: true,
};

const regexMap: Partial<Record<CountryCode | 'default', RegExp>> = {
  AT: /^ATU[a-z0-9]{8}$/i,
  NL: /^NL[a-z0-9\*\+]{12}$/i,
  default: /^[a-z]{2}[a-z0-9\*\+]{8,12}$/i,
};

function getValidationRules() {
  const rules: [string, ...any][] = [];

  if (countryhasUidMap[props.countryCode]) {
    rules.push(['matches', regexMap[props.countryCode] ?? regexMap.default]);
  }

  return rules;
}

const hasUid = ref(props.hasUid);
</script>
